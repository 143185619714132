import VAPI from "../../../http_common";
import { SERVICE_NAMES, HTTP_STATUS } from "../../../app_constants";
import router from "../../../router/index";
import store from "@/store";

function queryPermissions({ commit, dispatch }, payload) {
	if(store.getters.getCurrentProcess)
		VAPI.get(`/${SERVICE_NAMES.PERMISSION}?process=${store.getters.getCurrentProcess.id_process}`)
			.then((response) => {
				if (response.status == HTTP_STATUS.OK) {
					commit("setPermissions", response.data);
					dispatch("getMenu");
				} else if (response.status == HTTP_STATUS.NO_CONTENT) {
					commit("setError", { status: true, type: response.status });
				}
			})
			.catch((error) => {
				commit("setError", { status: true, type: error.response.status });
				console.error(error);
			});
}

function secureLogin({ commit, dispatch}, payload){
	commit("setAuthenticated", false);
	commit("setCurrentUser", null);
	commit("setContracts", false);
	commit("setContract", false);
	commit("selectCurrentProcess", false);
	commit("setMenu", false);
	commit('setExt', null)
	commit("setPermissions",null);
	commit("setCycle", null);
	commit("setAllCycles", null);
	//console.log(payload)
	let data = {
		email: payload.email,
		project: payload.project,
		hash: payload.hash,
		userAgent: navigator.userAgent,
	};
	VAPI.post(`/${SERVICE_NAMES.SECURE_LOGIN}`, data)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				commit("setAuthenticated", true);
				commit("setCurrentUser", response.data);
				dispatch("contractSecure",{
					project: response.data.project,
					contract: response.data.contract
				});
				//dispatch("getMenu");
				router.push({
					name: "home",
				});
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})
		.catch((error) => {
			commit("setError", { status: true, type: error.response.status });
			console.error(error);
		});
}

function logIn({ commit, dispatch }, payload) {
	//Clean cache
	commit("setAuthenticated", false);
	commit("setCurrentUser", null);
	commit("setContracts", false);
	commit("setContract", false);
	commit("selectCurrentProcess", false);
	commit("setMenu", false);
	commit('setExt', null)
	commit("setPermissions",null);
	commit("setCycle", null);
	commit("setAllCycles", null);
	let data = {
		email: payload.email,
		password: payload.password,
		userAgent: navigator.userAgent,
	};
	VAPI.post(`/${SERVICE_NAMES.LOGIN}`, data)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				commit("setAuthenticated", true);
				commit("setCurrentUser", response.data);
				dispatch("contract");
				//dispatch("getMenu");
				router.push({
					name: "home",
				});
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})
		.catch((error) => {
			commit("setError", { status: true, type: error.response.status });
			console.error(error);
		});
}

function logOut({ commit, dispatch }, payload) {
	let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	let data = { userAgent: navigator.userAgent };
	if (payload == 'idle')
		this._vm.$toast.warning('Sesión cerrada por inactividad', { timeout: false });
	if (currentUser == null) {
		commit("setAuthenticated", false);
		commit("setCurrentUser", null);
		commit("setContracts", false);
		commit("setContract", false);
		commit("selectCurrentProcess", false);
		commit("setMenu", false);
		commit('setExt', null)
		commit("setPermissions",null);
		commit("setCycle", null);
		commit("setAllCycles", null);
	} else {
		VAPI.post(`/${SERVICE_NAMES.LOGOUT}`, data)
			.then((response) => {
				commit("setAuthenticated", false);
				commit("setCurrentUser", null);
				commit("setContracts", false);
				commit("setContract", false);
				commit("selectCurrentProcess", false);
				commit("setMenu", false);
				commit('setExt', null)
				commit("setPermissions",null);
				commit("setCycle", null);
				commit("setAllCycles", null);
				if (payload != 'no_r')
					router.push({
						name: "login",
					});
			})
			.catch((error) => {
				console.error("An error occurred logout: " + error.toString());
				commit("setAuthenticated", false);
				commit("setCurrentUser", null);
				commit("setContracts", false);
				commit("setContract", false);
				commit("selectCurrentProcess", false);
				commit("setMenu", false);
				commit('setExt', null)
				commit("setPermissions",null);
				commit("setCycle", null);
				commit("setAllCycles", null);
				if (error.response != undefined)
					commit("setError", { status: true, type: error.response.status });
				if (payload != 'no_r')
					router.push({
						name: "login",
					});
			});
	}
}

function clearLoading({ commit }) {
	commit("decreaseLoading");
}

function setLoading({ commit }) {
	commit("increaseLoading");
}

function project({ commit, dispatch }, payload) {
	VAPI.get(`/${SERVICE_NAMES.GENERAL_DB}/projects?contract=${payload}`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				commit("setContract", response.data);
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})
		.catch((error) => {
			commit("setError", { status: true, type: "PRESTERROR01" });
			console.error(error);
		});
}

function contract({ commit, dispatch }) {
	VAPI.get(`/${SERVICE_NAMES.GENERAL_DB}/contracts`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				let contracts = response.data
				commit("setContracts", contracts);
				if( contracts.length < 2 ){
					dispatch('project', contracts[0].id_contract)
				}

			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})

		.catch((error) => {
			commit("setError", { status: true, type: "PRESTERROR01" });
			console.error(error);
		});
}

async function getCycles({ commit }, payload) {
	try {
		// Obtener ciclos desde la API
		const response = await VAPI.get(
			`/${SERVICE_NAMES.ACTIVITY}/cycle-complex?process=${store.getters.getCurrentProcess.id_process}`
		);

		if (response.status === HTTP_STATUS.OK) {
			const cycles = response.data;
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);
			// Filtrar los ciclos que incluyen la fecha actual
			const validCycles = cycles.filter(e =>
				new Date(e.value.dates[0].date).setHours(0, 0, 0, 0) <= currentDate &&
				new Date(e.value.dates[1].date).setHours(0, 0, 0, 0) >= currentDate
			);

			let cycle;

			if (payload !== null) {
				cycle = cycles.find(item => item.value.id === payload);
			}
			else if (validCycles.length > 0) {
				// Seleccionar el primer ciclo válido
				cycle = validCycles[0];
			} else {
				// Encontrar el ciclo más cercano
				cycle = cycles.reduce((closest, current) => {
					const currentStart = new Date(current.value.dates[0].date).setHours(0, 0, 0, 0);
					const currentEnd = new Date(current.value.dates[1].date).setHours(0, 0, 0, 0);
					const closestStart = new Date(closest.value.dates[0].date).setHours(0, 0, 0, 0);
					const closestEnd = new Date(closest.value.dates[1].date).setHours(0, 0, 0, 0);

					// Calcular diferencias mínimas entre fechas de inicio/fin
					const minDiffCurrent = Math.min(
						Math.abs(currentDate - currentStart),
						Math.abs(currentDate - currentEnd)
					);
					const minDiffClosest = Math.min(
						Math.abs(currentDate - closestStart),
						Math.abs(currentDate - closestEnd)
					);

					// Devolver el ciclo con la diferencia más pequeña
					return minDiffCurrent < minDiffClosest ? current : closest;
				}, cycles[0]);
			}
			// Guardar ciclo actual y todos los ciclos en el store
			commit("setCycle", cycle);
			commit("setAllCycles", cycles);
		} else {
			console.error(`Error: Código de estado ${response.status}`);
		}
	} catch (error) {
		// Manejo de errores
		console.error("Error al obtener ciclos:", error);
	}
}

async function setCurrentProcess({ commit , dispatch}, payload) {
	commit("selectCurrentProcess", payload);
	await dispatch("queryPermissions");
	await dispatch("getCycles", null);
}

function getMenu({ commit, dispatch }, payload) {
	VAPI.get(`/${SERVICE_NAMES.USER}/menu?process=${store.getters.getCurrentProcess.id_process}`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				let data = response.data
				commit('setMenu', data)
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		}).catch((error) => {
			commit("setError", { status: true, type: "PRESTERROR02" });
			console.error(error);
		});
}

async function getExtData({ commit, dispatch }, payload) {
    try {
        const response = await VAPI.post(`/${SERVICE_NAMES.USER}/external`, { token: payload });
        if (response.status === HTTP_STATUS.OK) {
            commit('setExt', response.data);
            commit('selectCurrentProcess', response.data.process);
            commit('setContracts', response.data.contract);
            commit("setContract", response.data.contract[0]);
            await dispatch("getCycles", response.data.iteration);
        } else if (response.status === HTTP_STATUS.NO_FOUND) {
            commit('setExt', null);
            if (!store.getters.isAuthenticated) {
                commit('selectCurrentProcess', null);
                commit('setContracts', null);
                commit("setContract", null);
                commit("setCycle", null);
                commit("setAllCycles", null);
            }
            commit("setError", { status: true, type: response.status });
        }
    } catch (error) {
        commit("setError", { status: true, type: "PRESTERROR02" });
        console.error(error);
    }
}

function insertSupports({commit, dispatch}, payload) {
	if (payload != null) {
		commit('updateCurrentProcessSupports', payload)
	}
}

function deleteSupports({commit, dispatch}, payload) {
	if (payload != null) commit('deleteCurrentProcessSupports', payload["supportId"]);
}

function projectSecure({ commit, dispatch }, payload) {
	VAPI.get(`/${SERVICE_NAMES.GENERAL_DB}/projects?contract=${payload.contract}`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				let projects = response.data
				commit("setContract", projects);
				if(projects && projects.processes.length < 2 ){
					dispatch("setCurrentProcess", projects.processes[0]);
				}
				else{
					let myProject = projects.processes.filter(el => el.id_process == payload.project)[0]
					dispatch("setCurrentProcess", myProject);
				}
				
			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})
		.catch((error) => {
			commit("setError", { status: true, type: "PRESTERROR01" });
			console.error(error);
		});
}

function contractSecure({ commit, dispatch }, payload) {
	VAPI.get(`/${SERVICE_NAMES.GENERAL_DB}/contracts`)
		.then((response) => {
			if (response.status == HTTP_STATUS.OK) {
				let contracts = response.data
				commit("setContracts", contracts);
				if( contracts.length < 2 ){
					dispatch('projectSecure', {
						contract: contracts[0].id_contract,
						project: payload.project
					})
				}
				else{
					let myContract = contracts.filter(el => el.id_contract == payload.contract)[0]
					dispatch('projectSecure', {
						contract: myContract.id_contract,
						project: payload.project
					})
				}

			} else if (response.status == HTTP_STATUS.NO_CONTENT) {
				commit("setError", { status: true, type: response.status });
			}
		})

		.catch((error) => {
			commit("setError", { status: true, type: "PRESTERROR01" });
			console.error(error);
		});
}

export default {
	logIn,
	secureLogin,
	logOut,
	clearLoading,
	setLoading,
	contract,
	project,
	contractSecure,
	projectSecure,
	setCurrentProcess,
	getMenu,
	getExtData,
	queryPermissions,
	getCycles,
	insertSupports,
	deleteSupports
};
